const cardArray = [
  {
    name: "Degu",
    tags: ["Graužikai", "Degu"],
    link: "/degu",
    img: "./img/animalCare/careDegu.png",
    info: "Tinkama degu priežiūra",
  },
  {
    name: "Jūrų kiaulytės",
    tags: ["Graužikai", "Jūrų kiaulytės"],
    link: "/juruKiaulytes",
    img: "./img/animalCare/careJuruKiaulyte.png",
    info: "Tinkama Jūrų kiaulyčių priežiūra",
  },
  {
    name: "Šinšilos",
    tags: ["Graužikai", "Šinšilos"],
    link: "/sinsilos",
    img: "./img/animalCare/careSinsila.png",
    info: "Tinkama Šinšilų priežiūra",
  },
  {
    name: "Žiurkėnai",
    tags: ["Graužikai", "Žiurkėnai"],
    link: "/ziurkenai",
    img: "./img/animalCare/careZiurkenai.png",
    info: "Tinkama žiurkėnų priežiūra",
  },
  {
    name: "Dekoratyvinės žiurkės",
    tags: ["Graužikai", "Žiurkės"],
    link: "/ziurkes",
    img: "./img/animalCare/careZiurkes.png",
    info: "Tinkama dekoratyvinių žiurkių priežiūra",
  },
  {
    name: "Barzdotoji agama",
    tags: ["Ropliai", "Barzdotoji agama"],
    link: "/barzdotojiAgama",
    img: "./img/animalCare/careAgama.png",
    info: "Tinkama barzdototojų agamų priežiūra",
  },
  {
    name: "Gyvatės ir žalčiai",
    tags: ["Ropliai", "Gyvatės", "Žalčiai"],
    link: "/gyvatesIrZalciai",
    img: "./img/animalCare/careGyvates.png",
    info: "Tinkama gyvačių ir žalčių priežiūra",
  },
  {
    name: "Iguana",
    tags: ["Ropliai", "Iguana"],
    link: "/iguana",
    img: "./img/animalCare/careIguana.png",
    info: "Tinkama iguanų priežiūra",
  },
  {
    name: "Leopardinis gekonas",
    tags: ["Ropliai", "Leopardinis gekonas"],
    link: "/leopardinisGekonas",
    img: "./img/animalCare/careGekonas.png",
    info: "Tinkama gekonų priežiūra",
  },
  {
    name: "Sausumos vėžliai",
    tags: ["Ropliai", "Sausumos vėžliai"],
    link: "/sausumosVezliai",
    img: "./img/animalCare/careSausiVezliai.png",
    info: "Tinkama sausumos vėžlių priežiūra",
  },
  {
    name: "Šalmuotasis chameleonas",
    tags: ["Ropliai", "Šalmuotasis chameleonas"],
    link: "/salmuotasisChameleonas",
    img: "./img/animalCare/careChameleonas.png",
    info: "Tinkama chameleonų priežiūra",
  },
  {
    name: "Vandens vėžliai",
    tags: ["Ropliai", "Vandens vėžliai"],
    link: "/vandensVezliai",
    img: "./img/animalCare/careSlapiVezliai.png",
    info: "Tinkama vandens vėžlių priežiūra",
  },
  {
    name: "Paukščiai",
    tags: ["Paukščiai", "Papūgos"],
    link: "/pauksciai",
    img: "./img/animalCare/carePauksciai.png",
    info: "Tinkama paukščių priežiūra",
  },
  {
    name: "Dekoratyviniai triušiai",
    tags: ["Kiti", "Dekoratyviniai triušiai"],
    link: "/dekoratyviniaiTriusiai",
    img: "./img/animalCare/careTriusiai.png",
    info: "Tinkama triušių priežiūra",
  },
  {
    name: "Gyvalazdės",
    tags: ["Kiti", "Gyvalazdės"],
    link: "/gyvalazdes",
    img: "./img/animalCare/careGyvalazdes.png",
    info: "Tinkama gyvalazdžių priežiūra",
  },
  {
    name: "Šeškai",
    tags: ["Kiti", "Šeškai"],
    link: "/seskai",
    img: "./img/animalCare/careSeskai.png",
    info: "Tinkama šeškų priežiūra",
  },
];

export default cardArray;
