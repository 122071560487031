import React from "react";
import "./usefullFact.scss";
export default function UsefullFact({ message }) {
  return (
    <div className="usefullFact">
      <svg
        width="38"
        height="29"
        viewBox="0 0 38 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.75 14.3281H4.125C3.56141 14.3281 3.02091 14.1042 2.6224 13.7057C2.22388 13.3072 2 12.7667 2 12.2031V4.76562C2 4.20204 2.22388 3.66154 2.6224 3.26302C3.02091 2.86451 3.56141 2.64062 4.125 2.64062H12.625C13.1886 2.64062 13.7291 2.86451 14.1276 3.26302C14.5261 3.66154 14.75 4.20204 14.75 4.76562V14.3281ZM14.75 14.3281C14.75 19.6406 12.625 22.8281 6.25 26.0156M36 14.3281H25.375C24.8114 14.3281 24.2709 14.1042 23.8724 13.7057C23.4739 13.3072 23.25 12.7667 23.25 12.2031V4.76562C23.25 4.20204 23.4739 3.66154 23.8724 3.26302C24.2709 2.86451 24.8114 2.64062 25.375 2.64062H33.875C34.4386 2.64062 34.9791 2.86451 35.3776 3.26302C35.7761 3.66154 36 4.20204 36 4.76562V14.3281ZM36 14.3281C36 19.6406 33.875 22.8281 27.5 26.0156"
          stroke="#2A2F8D"
          stroke-width="4"
          stroke-linecap="round"
        />
      </svg>
      <div className="usefullFactP">{message}</div>
    </div>
  );
}
